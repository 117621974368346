import {
    $, $doc, $body, bodyOverflow,
} from './_utility';


function initPluginFancybox() {
    // fix scrollbar in the fancybox
    let body = document.querySelector("body");
    let navToggler = document.querySelectorAll(".navbar-toggle");
    navToggler.forEach(button => {
        button.addEventListener('click', () => {
            setTimeout( () => {
                body.classList.toggle( 'fancybox-open' );
            }, 10 );
        });
    });
}

export { initPluginFancybox };

