// Import libraries
import $ from "jquery";
import "object-fit-images";
// import Popper from "popper.js";
import "bootstrap";
import "bootstrap-validator";
// import Rellax from "rellax";
import "jquery-countdown";
// import Isotope from "isotope-layout";
// import Packery from "isotope-packery";
// import Jarallax from "jarallax";
import "@fancyapps/fancybox";
// import L from "leaflet";
import lottie from 'lottie-web';
import axios from "axios";
import Typewriter from 'typewriter-effect/dist/core';

// Import custom components
import { initShowScroll } from "./parts/initShowScroll";
import { initCursor } from "./parts/initCursor";
import { initNavbar } from "./parts/initNavbar";
import { initInteractiveLinks } from "./parts/initInteractiveLinks";
import { initPluginSwiper } from "./parts/initPluginSwiper";
import { initPluginAnime } from "./parts/initPluginAnime";
import { initPluginImagesLoaded } from "./parts/initPluginImagesLoaded";
import { initPluginRellax } from "./parts/initPluginRellax";
import { initPluginIsotope } from "./parts/initPluginIsotope";
import { initPluginFancybox } from "./parts/initPluginFancybox";
import { initPluginOFI } from "./parts/initPluginOFI";

class THEMEBAU {
    init() {
        const methods = [
            "initShowScroll",
            "initCursor",
            "initNavbar",
            "initPluginSwiper",
            "initInteractiveLinks",
            "initPluginAnime",
            "initPluginRellax",
            "initPluginIsotope",
        ];

        methods.forEach((method) => {
            if (this[method]) {
                this[method]();
            }
        });

        return this;
    }

    initShowScroll() {
        return initShowScroll.call(this);
    }

    initCursor() {
        return initCursor.call(this);
    }

    initNavbar() {
        return initNavbar.call(this);
    }

    initInteractiveLinks() {
        return initInteractiveLinks.call(this);
    }

    initPluginSwiper() {
        return initPluginSwiper.call(this);
    }

    initPluginAnime() {
        return initPluginAnime.call(this);
    }

    initPluginImagesLoaded() {
        return initPluginImagesLoaded.call(this);
    }

    initPluginRellax() {
        return initPluginRellax.call(this);
    }

    initPluginIsotope() {
        return initPluginIsotope.call(this);
    }

    initPluginFancybox() {
        return initPluginFancybox.call(this);
    }

    initPluginOFI() {
        return initPluginOFI.call(this);
    }
}
window.Themebau = new THEMEBAU().init();

    /**
 * epunks blog active links
 */

    function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const visibleThreshold = 0.5; // Otherwise .active class is not triggering on shorter sections
        return (
            (rect.top <= windowHeight * (1 - visibleThreshold)) &&
            (rect.bottom >= windowHeight * visibleThreshold)
        );
    }

    let currentActiveLink = null;

    function updateActiveLink() {
        const anchorLinks = document.querySelectorAll('.links a');
        let mostInViewLink = null;
        let mostInViewPercentage = 0;

        anchorLinks.forEach(link => {
            const sectionId = link.getAttribute('href');
            const section = document.querySelector(sectionId);

            if (isElementInViewport(section)) {
                const inViewPercentage = getPercentageInView(section);
                if (inViewPercentage > mostInViewPercentage) {
                    mostInViewLink = link;
                    mostInViewPercentage = inViewPercentage;
                }
            }

            link.classList.remove('active');
        });

        if (mostInViewLink) {
            mostInViewLink.classList.add('active');
            currentActiveLink = mostInViewLink;
        } else if (currentActiveLink) {
            currentActiveLink.classList.add('active');
        }
    }

    function getPercentageInView(el) {
        const rect = el.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const visibleHeight = Math.max(0, Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0));
        return (visibleHeight / el.clientHeight) * 100;
    }

    window.addEventListener('scroll', updateActiveLink);
    window.addEventListener('load', updateActiveLink); // Update active link on page load

    updateActiveLink();

    /*
    * epunks landingpages active Element
    */
let currentActiveElement = null;
    function updateActiveElement() {
        const elements = document.querySelectorAll('.scroll-element');
        let mostInViewElement = null;
        let mostInViewPercentage = 0;

        elements.forEach(element => {
            // const sectionId = element.getAttribute('href');
            // const section = document.querySelector('.scroll-element');

            if (isElementInViewport(element)) {
                const inViewPercentage = getPercentageInView(element);
                if (inViewPercentage > mostInViewPercentage) {
                    mostInViewElement = element;
                    mostInViewPercentage = inViewPercentage;
                }
            }

            element.classList.remove('mostInViewport');
        });

        if (mostInViewElement) {
            mostInViewElement.classList.add('mostInViewport');
            currentActiveElement = mostInViewElement;
        } else if (currentActiveElement) {
            currentActiveElement.classList.add('mostInViewport');
        }
    }
    window.addEventListener('scroll', updateActiveElement);
    updateActiveElement();

/**
 * epunks blog article loader
 */
window.axios = axios;
$("#blog-overview-lazy-load").on("click", async function (e) {
    e.preventDefault();

    const $loadMoreButton = $("#blog-overview-lazy-load");

    const lastLoadedArticle = parseInt(
        $(".isotope-grid .card-blog").last().data("offset")
    );

    axios.get("/blog/more-articles/" + lastLoadedArticle).then((response) => {
        if (response.status == 200) {
            if (response.data.length > 0) {
                $(response.data).each(function () {
                    let $template = $(".card-blog").first().clone();
                    $template.css("display", "none");

                    // Neue Zeile mit inhalten befüllen
                    $template.attr("data-offset", lastLoadedArticle + 8);
                    $template
                        .find("a")
                        .attr("href", BLOG_DETAIL_URL + "/" + this.slug);
                    $template.find(".card-title").text(this.title);

                    //$template.find('.card-date').text(day + ' ' + createdAt.getMonth() + ', ' + createdAt.getFullYear());
                    $template.find(".card-date").text(this.display_date);
                    $template.find(".card-text").text(this.lead);

                    // bild definiert? (bei alten beiträge)
                    let blogImagePath = IMAGE_PATH + this.image_01;

                    // abwarten bis der browser das bild gezogen hat, dann einblenden
                    $template
                        .find("img")
                        .first()
                        .replaceWith(
                            '<img src="' +
                                blogImagePath +
                                '" alt="Bild zum Artikel ' +
                                this.title +
                                '">' +
                                this.title +
                                "</h2>"
                        );

                    //return false;
                    $(".isotope-grid").append($template);
                    $template.fadeIn(500);
                });
            } else {
                $loadMoreButton.text("Ende Gelände");
            }
        }
    });
});

function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return elemBottom <= docViewBottom && elemTop >= docViewTop;
}

$(window).on("scroll", function () {
    $(".rainbow-animation").each(function () {
        if (isScrolledIntoView(this) === true) {
            $(this).addClass("rainbow-temporary");
        }
    });
});

document.addEventListener("DOMContentLoaded", function () {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

    if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function (
            entries,
            observer
        ) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;

                    // Add a load event listener to handle loading state
                    lazyImage.addEventListener("load", function () {
                        // Remove the loading indicator
                        lazyImage.classList.remove("loading");

                        // Add a class to animate the image
                        lazyImage.classList.add("fade-in");

                        lazyImageObserver.unobserve(lazyImage);
                    });

                    // Add an error event listener to handle when an image fails to load
                    lazyImage.addEventListener("error", function () {
                        // Handle the error. For example, replace the image with a placeholder
                        lazyImage.src = "placeholder.jpg";
                    });

                    // Add a class for the loading state
                    lazyImage.classList.add("loading");

                    // Remove the lazy class
                    lazyImage.classList.remove("lazy");
                }
            });
        });

        lazyImages.forEach(function (lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // Possibly fall back to a more compatible method here
    }
});

if(document.getElementById("lottieBeziers")){
lottie.loadAnimation({
    container: lottieBeziers, // the dom element that will contain the animation
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: './assets/lottie/beziers.json' // the path to the animation json
  });
}
// // Code for Circle in Button
// window.onload = function() {
//     // get all buttons by their class name
//     var btns = document.getElementsByClassName('btn-with-ball');
//
//     for(var i = 0; i < btns.length; i++) {
//         // create a new span element
//         var span = document.createElement('span');
//
//         // add the btn-ball class to the span
//         span.className = 'btn-ball';
//
//         // add the style to the span
//         span.style.transform = 'translateY(0px)';
//
//         // append the span to the current button
//         btns[i].appendChild(span);
//     }
// }

// Wird verwendet für die Überschrift der Job Seite (/lets-work)
var app = document.getElementById('jobs_typewriter');
var typewriter = new Typewriter(app, {
    loop: true,
    delay: 40,
    devMode: false
});

typewriter.typeString('Punk encuentra <br class="d-lg-none"> carrera.<br>Únete a la <br class="d-lg-none"> banda.')
    .pauseFor(3000)
    .deleteAll(20)
    .typeString('Punk meets <br class="d-md-none"> career.<br>Become a <br class="d-md-none"> part of our <br class="d-lg-none"> gang.')
    .pauseFor(3000)
    .deleteAll(20)
    .typeString('Punk <br class="d-md-none"> rencontre <br class="d-lg-none"> carrière.<br>Rejoins le <br class="d-md-none"> gang.')
    .pauseFor(3000)
    .deleteAll(20)
    .typeString('<span style="font-family: Klingon; ">mughovlaHbe\' <br class="d-lg-none"> ghu\'vam.<br> qeylIS \'e\' <br class="d-md-none"> luQoy.</span>')
    .pauseFor(3000)
    .deleteAll(20)
    .start();


// Function to check if an element is in the viewport
function isLogoInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

// Function to add class when the logo element is in view
function showLogoOnScroll() {
    const logoElement = document.getElementById('ibk-logo-container');

    if (isLogoInViewport(logoElement)) {
        logoElement.classList.add('show-height');
        window.removeEventListener('scroll', showLogoOnScroll); // Remove the listener once animation is triggered
    }
}

// Listen for scroll events
window.addEventListener('scroll', showLogoOnScroll);
